import * as React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CoingeckoProvider } from '../contexts/coingecko';
import { ConnectionProvider } from '../contexts/ConnectionContext';
import { LoaderProvider } from '../components/Loader';
import { SPLTokenListProvider } from '../contexts/tokenList';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { WalletProvider } from '../contexts/WalletContext';
import { AppLayout } from './Layout';
import { Claim } from './Claim';
export var App = function () {
    var muiDarkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#d0bdf4',
            },
            secondary: {
                main: 'rgb(114, 182, 207)',
            },
        },
    });
    return (React.createElement(ThemeProvider, { theme: muiDarkTheme },
        React.createElement(BrowserRouter, null,
            React.createElement(ConnectionProvider, null,
                React.createElement(WalletModalProvider, null,
                    React.createElement(SPLTokenListProvider, null,
                        React.createElement(CoingeckoProvider, null,
                            React.createElement(LoaderProvider, null,
                                React.createElement(WalletProvider, null,
                                    React.createElement(AppLayout, null,
                                        React.createElement(Switch, null,
                                            React.createElement(Route, { exact: true, path: "/", component: Claim })),
                                        React.createElement("div", { className: "banner" })))))))))));
};
export default hot(module)(App);
