var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useWallet } from '@solana/wallet-adapter-react';
import suns from './suns.gif';
import closeSvg from './close.svg';
import { Cog, CurrentUserBadge, CurrentUserBadgeMobile, } from '../CurrentUserBadge';
import { ConnectButton } from '../ConnectButton';
function getWindowDimensions() {
    var width = window.innerWidth, height = window.innerHeight;
    return {
        width: width,
        height: height,
    };
}
export function useWindowDimensions() {
    var _a = __read(React.useState(getWindowDimensions()), 2), windowDimensions = _a[0], setWindowDimensions = _a[1];
    React.useEffect(function () {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowDimensions;
}
export var LogoLink = function () {
    return (React.createElement(Link, { to: "/" },
        React.createElement("img", { className: "app-logo", src: suns })));
};
export var MetaplexMenu = function () {
    var width = useWindowDimensions().width;
    var _a = __read(React.useState(false), 2), isModalVisible = _a[0], setIsModalVisible = _a[1];
    var connected = useWallet().connected;
    if (width <= 768)
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { title: React.createElement(LogoLink, null), visible: isModalVisible, footer: null, className: 'modal-box', closeIcon: React.createElement("img", { onClick: function () { return setIsModalVisible(false); }, src: closeSvg }) },
                React.createElement("div", { className: "site-card-wrapper mobile-menu-modal" },
                    React.createElement("div", { className: "actions" }, !connected ? (React.createElement("div", { className: "actions-buttons" },
                        React.createElement(ConnectButton, { onClick: function () { return setIsModalVisible(false); } }, "Connect Wallet"))) : (React.createElement(React.Fragment, null,
                        React.createElement(CurrentUserBadgeMobile, { showBalance: false, showAddress: true, iconSize: 24, closeModal: function () {
                                setIsModalVisible(false);
                            } }),
                        React.createElement("div", { className: "wallet-wrapper" },
                            React.createElement(Button, { className: "wallet-key", onClick: function () { return setIsModalVisible(false); } },
                                React.createElement(Link, { to: "/" }, homeSvg(location.pathname === '/')))),
                        React.createElement(Cog, null)))))),
            React.createElement(MenuOutlined, { onClick: function () { return setIsModalVisible(true); }, style: { fontSize: '1.4rem' } })));
    return null;
};
export var MobileNavbar = function () {
    return (React.createElement("div", { id: "mobile-navbar" },
        React.createElement(LogoLink, null),
        React.createElement("div", { className: "mobile-menu" },
            React.createElement(MetaplexMenu, null))));
};
var homeSvg = function (filled) {
    var props = filled
        ? {
            fill: 'none',
            stroke: '#fbb954',
            strokeWidth: '1',
        }
        : {
            fill: 'none',
            stroke: '#fbb954',
            strokeWidth: '1',
        };
    return (React.createElement("svg", __assign({ "aria-label": "Home", height: "20", width: "20", viewBox: "0 0 24 24" }, props),
        React.createElement("path", { d: "M22 23h-6.001a1 1 0 01-1-1v-5.455a2.997 2.997 0 10-5.993 0V22a1 1 0 01-1 1H2a1 1 0 01-1-1V11.543a1.002 1.002 0 01.31-.724l10-9.543a1.001 1.001 0 011.38 0l10 9.543a1.002 1.002 0 01.31.724V22a1 1 0 01-1 1z" })));
};
export var AppBar = function () {
    var connected = useWallet().connected;
    var location = useLocation();
    return (React.createElement(React.Fragment, null,
        React.createElement(MobileNavbar, null),
        React.createElement("div", { id: "desktop-navbar" },
            React.createElement("div", { className: "app-left", onClick: function (e) {
                    e.preventDefault();
                    window.location.href = 'https://sunsstudio.com';
                } },
                React.createElement(LogoLink, null)),
            React.createElement("div", { className: "app-right" },
                !connected && (React.createElement(ConnectButton, { style: { padding: '16px 12px', fontFamily: 'Roboto Mono' } }, "Connect Wallet")),
                connected && (React.createElement(React.Fragment, null,
                    React.createElement(CurrentUserBadge, { showBalance: false, showAddress: true, iconSize: 24 }),
                    React.createElement("div", { className: "wallet-wrapper" },
                        React.createElement(Button, { className: "wallet-key" },
                            React.createElement(Link, { to: "/" }, homeSvg(location.pathname === '/')))),
                    React.createElement(Cog, null)))))));
};
